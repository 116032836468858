


























































import { Component, Mixins, Vue } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import TestStudentHistoryHeader from '@/components/organisms/TestStudentHistoryHeader.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import SelectSubject from '@/components/atoms/SelectSubject.vue'
import SelectPeriod from '@/components/organisms/SelectPeriod.vue'
import ChartLineUnderstandingRelative, { ChartRecord } from '@/components/atoms/ChartLineUnderstandingRelative.vue'
import AccordionStudentHistory, { GradeHistories } from '@/components/organisms/AccordionStudentHistory.vue'
import SubjectSituationWebApi from '@/mixins/history/SubjectSituationWebApi'
import LoadEnableSubjectsApi from '@/mixins/utils/LoadEnableSubjectsApi'
import SelectBase from '@/components/atoms/SelectBase.vue'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'

@Component({
  components: {
    SidebarSwitcher,
    TestStudentHistoryHeader,
    ColoredBox,
    ChartLineUnderstandingRelative,
    SelectSubject,
    SelectPeriod,
    AccordionStudentHistory,
    SelectBase,
  },
})
export default class SubjectSituation extends Mixins(
  SubjectSituationWebApi,
  LoadEnableSubjectsApi,
  ClassModeChangeAble
) {
  // クラスモード: 定期テスト
  private readonly classMode = 'TK'

  private selectedSubject = this.$route.query.selectedSubject ? String(this.$route.query.selectedSubject) : ''

  // 教科プルダウン表示対象、教科コードの配列 ex.=> ['su', 'ei', 'ko']
  private enableSubjects: string[] = []

  private selectTestOptions: { text: string; value: string }[] = []

  private selectedTerm = ''

  private hasTermDate = true

  private termExamId: number | undefined

  private get branchId() {
    return Vue.prototype.$cookies.get('dataGdls').branchId
  }

  private get studentUserId() {
    return Vue.prototype.$cookies.get('authGdls').currentApiUserId
  }

  private get selectedText() {
    return this.selectTestOptions.find((option) => {
      return option.value === this.selectedTerm
    })?.text
  }

  private get enableSelectedSubject() {
    return this.enableSubjects.includes(this.selectedSubject) ? this.selectedSubject : ''
  }

  // チャートデータ
  private chartDatas: ChartRecord[] = []

  // 学習結果
  private result = {
    questionCount: 0,
    correctCount: 0,
    hour: 0,
    minute: 0,
  }

  // 表示日付
  private dateSteps: string[] = []

  // 項目毎の累計理解度 rateの位置は最大値の位置で表示する
  private gradeHistories: GradeHistories[] = []

  private async mounted(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.getTermExamsTerms()
    await this.checkClassModeAndSideMenuMode()
    if (this.hasTermDate) {
      await this.loadEnableSubjects()
      await this.loadChartRecords()
      await this.loadGradeHistories()
    }
    Vue.prototype.$loading.complete()
  }

  // 期間プルダウン変更時
  private async onChangeTerm() {
    Vue.prototype.$loading.start()
    await this.loadChartRecords()
    await this.loadGradeHistories()
    Vue.prototype.$loading.complete()
  }

  // 教科プルダウン変更時
  private async onChangeSubject() {
    Vue.prototype.$loading.start()
    await this.loadChartRecords()
    await this.loadGradeHistories()
    Vue.prototype.$loading.complete()
  }

  /**
   * チャート情報取得
   */
  private async loadChartRecords() {
    if (this.selectedSubject == '') return

    const { chartDatas, result, dateSteps } = await this.loadChartRecordsApi({
      userId: this.studentUserId,
      term: this.selectedTerm,
      subjectCode: this.selectedSubject,
      classMode: this.classMode,
    })
    // チャート用のスコア配列をセット
    this.chartDatas = chartDatas
    // 学習結果セット
    this.result = result
    // 表示日付セット
    this.dateSteps = dateSteps
  }

  /**
   * 項目毎の理解度取得
   */
  private async loadGradeHistories() {
    if (this.selectedSubject == '') return

    const result = await this.loadGradeHistoriesApi({
      userId: this.studentUserId,
      subjectCode: this.selectedSubject,
      classMode: this.classMode,
      term: this.selectedTerm,
    })
    // 項目毎の累計理解度セット
    this.gradeHistories = result['curriculums']
    // 正確なterm_exam_idをセット
    this.termExamId = result['termExamId']
  }

  /**
   * サービス設定で有効な教科を取得し、教科プルダウンに反映
   */
  private async loadEnableSubjects() {
    // プルダウン候補の取得
    this.enableSubjects = (await this.loadGdlsSubjectsApi(this.branchId, this.studentUserId)).map((subject) => {
      return subject.code
    })
    // デフォルトの教科セット
    this.selectedSubject = this.selectedSubject === '' ? this.enableSubjects[0] : this.selectedSubject
  }

  // テスト対策期間プルダウン生成
  private async getTermExamsTerms() {
    await Vue.prototype.$http.httpWithToken
      .get('/termExams/terms', { params: { userId: this.studentUserId } })
      .then((res: any) => {
        if (res.data.length === 0) {
          this.hasTermDate = false
          return
        }
        this.selectTestOptions = res.data.map((term: { id: string; gradeName: string; termName: string }) => {
          return { text: `${term.gradeName} ${term.termName}`, value: String(term.id) }
        })
        const selectedIndex = res.data.findIndex((term: { isSelected: boolean }) => {
          return term.isSelected === true
        })
        const selected = res.data[selectedIndex]
        this.selectedTerm = String(selected.id)
      })
  }
}
